<template>
  <div class="multi-select">
    <v-row no-gutters class="sw-mx-n1">
      <v-col
        v-for="(item, i) in selectedItems"
        :key="`item-${i}`"
        cols="12"
        class="sw-px-1 sw-pb-3"
      >
        <v-tooltip top color="dark-grey">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              close
              outlined
              :style="cssVariables"
              class="selected-item"
              @click:close="removeItem(item.id)"
            >
              <div class="text-overflow--ellipsis">
                {{ item.name }}
                <span v-if="item.price" class="ml-2"
                  >({{ item.price / 100 }} {{ groupCurrency }})</span
                >
              </div>
            </v-chip>
          </template>
          <div>
            {{ item.name }}
            <span v-if="item.price" class="ml-2"
              >({{ item.price / 100 }} {{ groupCurrency }})</span
            >
          </div>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-autocomplete
      v-model="input"
      :errorMessages="errorMessages"
      :items="availableItems"
      :menu-props="{ closeOnContentClick: false, closeOnClick: false }"
      item-text="name"
      item-value="id"
      :placeholder="$vuetify.lang.t('$vuetify.chooseAnswer')"
      flat
      background-color="#f2f2f2"
      class="custom-select rounded-lg"
      solo
      append-icon="mdi-magnify"
      ref="combobox"
      return-object
      @input="selectItem"
    >
      <template slot="no-data">
        <div style="padding: 7px 13px" class="sw-small grey--text">
          {{ $vuetify.lang.t("$vuetify.noDataText") }}
        </div>
      </template>
      <template slot="item" slot-scope="data">
        <div class="text-overflow--wrap py-3">
          <span>{{ data.item.name }}</span>
          <span v-if="data.item.price" class="ml-2">
            ({{ data.item.price / 100 }} {{ groupCurrency }})
          </span>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: String,
    },
  },
  data: () => ({
    input: null,
  }),
  computed: {
    selectedItems() {
      return this.items.filter(
        (el) => this.value.includes(el.id) || this.value.includes(el),
      );
    },
    availableItems() {
      return this.items.filter(
        (el) => !this.value.includes(el.id) && el.usage_available != 0,
      );
    },
    groupCurrency() {
      return this.$store?.getters?.group?.currency;
    },
  },
  methods: {
    selectItem(item) {
      this.$nextTick(() => {
        this.input = null;

        if (item) {
          this.$emit("input", [...this.value, ...[item.id]]);
        }
      });
    },
    removeItem(id) {
      if (!id) return;

      let updatedValue = this.value.filter((el) => el !== id);

      this.$emit("input", updatedValue);
    },
  },
};
</script>

<style lang="scss">
.multi-select {
  .selected-item {
    width: 100% !important;

    .v-chip__content {
      width: 100% !important;
      display: flex;

      .v-icon {
        margin-left: auto;
        color: var(--secondary-color) !important;
      }
    }
  }
}

.text-overflow--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
