<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    full-width
    max-width="290"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="computedDateFormattedDate"
        required
        :errorMessages="errorMessages"
        :placeholder="dateFormat"
        no-resize
        flat
        background-color="#f2f2f2"
        class="rounded-lg registration-field-rounded"
        readonly
        v-on="on"
        solo
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="model"
      reactive
      no-title
      scrollable
      :color="gmPrimaryColor"
      @change="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      type: String,
    },
    errorMessages: {
      type: String,
    },
  },
  data: () => ({
    dateFormat: "YYYY-MM-DD",
    menu: false,
  }),
  computed: {
    computedDateFormattedDate() {
      return this.model ? moment(this.model).format(this.dateFormat) : "";
    },
    model: {
      get() {
        return this.value ? moment(this.value).format("YYYY-MM-DD") : "";
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    this.getDateFormat();
  },
  methods: {
    async getDateFormat() {
      const response = await this.$http.get(
        `/settings/public/global.date_format`,
      );

      if (!response.data.data.value) return;

      this.dateFormat = response.data.data.value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
